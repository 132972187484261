import { useMutation } from '@apollo/client';
import { Button, Form, Select as Antdselect, Space, Typography } from 'antd';
import React, { useEffect } from 'react';
import {
  ASSET_CATEGORY,
  MODULE_TYPES,
  STATUS_TYPES,
  THEMES
} from '../../../../../common/constants';
import { SelectAsset } from '../../../../videos/components/FormInputs';
import { FORM_TYPES } from '../../../context/EditPageProvider';
import {
  CREATE_PAGE_MODULE,
  UPDATE_PAGE_MODULE
} from '../../../graphql/Mutations';
import {
  ActionButton,
  ACTION_TYPES,
  initialActionValues,
  initialAssetValues
} from './CTAForm';
import {
  ModuleFields,
  Permissions,
  ShowFields,
  ThemePicker
} from './FormInputs';

const IMAGE_ORIENTATIONS = {
  LEFT: 'LEFT',
  RIGHT: 'RIGHT'
};
const IMAGE_ORIENTATIONS_OPTIONS = [
  { label: 'Left', value: IMAGE_ORIENTATIONS.LEFT },
  { label: 'Right', value: IMAGE_ORIENTATIONS.RIGHT }
];

const IMAGE_TYPES = {
  SQUARE: 'SQUARE',
  LANDSCAPE: 'LANDSCAPE',
  PORTRAIT: 'PORTRAIT'
};
const IMAGE_TYPES_OPTIONS = [
  { label: 'Square', value: IMAGE_TYPES.SQUARE },
  { label: 'Landscape', value: IMAGE_TYPES.LANDSCAPE },
  { label: 'Portrait', value: IMAGE_TYPES.PORTRAIT }
];

const initialValues = {
  title: '',
  description: '',
  status: STATUS_TYPES.PUBLISHED,
  permissions: [],
  settings: {
    description: true,
    primaryAction: true,
    secondaryAction: true
  },
  config: {
    asset: {
      ...initialAssetValues
    },
    imageOrientation: IMAGE_ORIENTATIONS.LEFT,
    theme: THEMES.BLUE,
    imageType: IMAGE_TYPES.LANDSCAPE,
    primaryAction: {
      ...initialActionValues
    },
    secondaryAction: {
      ...initialActionValues
    }
  }
};

const SETTINGS = [
  {
    name: 'description',
    label: 'Description',
    allowedTypes: [MODULE_TYPES.INFORMATION]
  },
  {
    name: 'primaryAction',
    label: 'primary Action',
    allowedTypes: [MODULE_TYPES.INFORMATION]
  },
  {
    name: 'secondaryAction',
    label: 'Secondary Action',
    allowedTypes: [MODULE_TYPES.INFORMATION]
  }
];

const INFORMATION_THEMES = [
  THEMES.BLUE,
  THEMES.YELLOW,
  THEMES.BLUE_TINT,
  THEMES.MOSS,
  THEMES.MOSS_TINT,
  THEMES.BLUE_SHADE,
  THEMES.NEUTRAL_900,
  THEMES.NEUTRAL_300
];

const MODULE_KEYS = {
  [MODULE_TYPES.INFORMATION]: 'informationModule'
};

const CONFIG_TITLE = {
  [MODULE_TYPES.INFORMATION]: 'Information Configs'
};

const InformationForm = ({
  form: { type: formType, moduleId, defaultValues, index: order },
  pageId,
  type,
  onCancel,
  onSuccess,
  onSettingsChange
}) => {
  const [form] = Form.useForm();
  const isEdit = formType === FORM_TYPES.EDIT;

  const settingsProps = Form.useWatch(['settings'], form);
  useEffect(() => {
    if (settingsProps) {
      onSettingsChange(settingsProps);
    }
  }, [settingsProps]);

  const [addEditModule, { loading }] = useMutation(
    isEdit ? UPDATE_PAGE_MODULE : CREATE_PAGE_MODULE
  );

  useEffect(() => {
    if (moduleId && defaultValues && formType === FORM_TYPES.EDIT && form) {
      form.setFieldsValue({
        title: defaultValues?.title ?? '',
        description: defaultValues?.description ?? '',
        status: defaultValues?.status ?? STATUS_TYPES.DRAFT,
        permissions:
          defaultValues?.permissions?.map((value) => ({
            label: value,
            value
          })) ?? [],
        settings: {
          ...initialValues.settings,
          ...defaultValues?.moduleData?.settings
        },
        config: {
          asset: defaultValues?.moduleData?.config?.asset ?? {
            ...initialAssetValues
          },
          theme: defaultValues?.moduleData?.config?.theme ?? THEMES.BLUE,
          imageOrientation:
            defaultValues?.moduleData?.config?.imageOrientation ??
            IMAGE_ORIENTATIONS.LEFT,
          imageType:
            defaultValues?.moduleData?.config?.imageType ??
            IMAGE_TYPES.LANDSCAPE,
          primaryAction: {
            ...defaultValues?.moduleData?.config?.primaryAction,
            asset: defaultValues?.moduleData?.config?.primaryAction?.asset ?? {
              ...initialAssetValues
            }
          },
          secondaryAction: {
            ...defaultValues?.moduleData?.config?.secondaryAction,
            asset: defaultValues?.moduleData?.config?.secondaryAction
              ?.asset ?? {
              ...initialAssetValues
            }
          }
        }
      });
    }
  }, [form, moduleId, formType, defaultValues, form, initialValues]);

  const handleSubmit = ({ config, settings, permissions, ...rest }) => {
    const key = MODULE_KEYS[type];
    if (!key) return;

    // eslint-disable-next-line no-shadow
    const { asset, primaryAction, secondaryAction, ...restConfig } =
      config ?? {};

    const payload = {
      ...(!isEdit && {
        type,
        order: order + 1
      }),
      permissions: permissions?.map(({ value }) => value),
      ...rest,
      [key]: {
        settings,
        config: {
          ...restConfig,
          assetId: asset?.id ?? null,
          primaryAction: {
            title: primaryAction?.title ?? '',
            type: primaryAction?.type ?? ACTION_TYPES.EXTERNAL,
            url: primaryAction?.url ?? '',
            assetId: primaryAction?.asset?.id ?? null
          },
          secondaryAction: {
            title: secondaryAction?.title ?? '',
            type: secondaryAction?.type ?? ACTION_TYPES.EXTERNAL,
            url: secondaryAction?.url ?? '',
            assetId: secondaryAction?.asset?.id ?? null
          }
        }
      }
    };

    addEditModule({
      variables: { data: payload, id: isEdit ? moduleId : pageId }
    }).then(
      ({
        data: {
          addUpdatedPageModule: { pageModule }
        }
      }) => {
        onSuccess(pageModule);
      }
    );
  };

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={handleSubmit}
      initialValues={initialValues}
    >
      <ModuleFields />
      <Space className="w-full" direction="vertical">
        <ShowFields settings={SETTINGS} type={type} />
        <Space className="w-full" direction="vertical">
          <Typography.Text>{CONFIG_TITLE[type]}</Typography.Text>
          <div>
            <Form.Item label="Theme" name={['config', 'theme']}>
              <ThemePicker themes={INFORMATION_THEMES} />
            </Form.Item>
            <Form.Item label="Image" name={['config', 'asset']}>
              <SelectAsset
                modalTitle="Select Image"
                categoryKey={ASSET_CATEGORY.IMAGE}
                btnText="Image"
                dataSelector={({ id, url }) => ({
                  id,
                  url
                })}
              />
            </Form.Item>
            <Form.Item
              label="Image Orientation"
              name={['config', 'imageOrientation']}
            >
              <Antdselect options={IMAGE_ORIENTATIONS_OPTIONS} />
            </Form.Item>
            <Form.Item label="Image Type" name={['config', 'imageType']}>
              <Antdselect options={IMAGE_TYPES_OPTIONS} />
            </Form.Item>
            <ActionButton
              name="primaryAction"
              label="Primary Action"
              showAsset
            />
            <ActionButton
              name="secondaryAction"
              label="Secondary Action"
              showAsset
            />
          </div>
        </Space>
        <Permissions />
        <div className="d-flex button-section">
          <Space>
            <Form.Item>
              <Button
                type="text"
                htmlType="submit"
                className="text-btn mr-8"
                size="middle"
                disabled={loading}
                loading={loading}
              >
                Save
              </Button>
            </Form.Item>

            <Form.Item>
              <Button
                disabled={loading}
                onClick={onCancel}
                type="text"
                className="text-btn2"
              >
                Cancel
              </Button>
            </Form.Item>
          </Space>
        </div>
      </Space>
    </Form>
  );
};

export default InformationForm;
